import { createRouter, createWebHashHistory } from "vue-router";

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition
  }
  return { left:0, top:0};
}

const routes = [
  {
    path: "/",
    component: () => import("../views/LayoutView.vue"),
    children: [
      {
        path: "/",
        component: () => import("../views/HomeView.vue"),
      },
      {
        path: "products",
        component: () => import("../views/ProductsList.vue"),
      },
      {
        path: 'product/:id',
        component: () => import('../views/ProductView.vue')
      },
      {
        path: "works",
        component: () => import("../views/WorksList.vue"),
      },
      {
        path: "work/:id",
        component: () => import("../views/WorkView.vue"),
      },
      {
        path: "contact",
        component: () => import("../views/ContactView.vue"),
      },
      {
        path: "service",
        component: () => import("../views/ServiceView.vue"),
      },
      {
        path: "content",
        component: () => import("../views/CusContent.vue"),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior
});

export default router;
